input {
  &[type='text'],
  &[type='tel'],
  &[type='date'],
  &[type='datetime-local'],
  &[type='number'],
  &[type='search'],
  &[type='time'],
  &[type='url'],
  &[type='password'],
  &[type='email'] {
    &.ng-touched.ng-invalid {
      border-color: nb-theme(color-danger-500);
    }
  }
}
