nb-user {
  .user-container {
    overflow: hidden;
  }

  .info-container {
    overflow: hidden;
  }

  .user-name,
  .user-title {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
