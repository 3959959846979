nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
  top: 0;
}

nb-sidebar.dark {
  background-color: nb-theme(color-primary-500);
  color: white;

  .main-container .main-container-fixed {
    top: 0;
  }

  nb-user {
    .user-name,
    .user-title {
      color: white;
    }
  }

  nb-menu {
    .menu-item {
      border-color: transparent;

      a {
        color: nb-theme(color-primary-100);

        .menu-icon {
          color: nb-theme(color-primary-300);
        }
      }

      a.active {
        color: white;

        .menu-icon {
          color: white;
        }
      }
    }
  }
}
