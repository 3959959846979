.nb-theme-pluginplay {
  @import './button';
  @import './card';
  @import './dialogs';
  @import './input';
  @import './sidebar';
  @import './table';
  @import './user';
  @import "./a";

  .legal {
    p,
    li {
      font-size: 0.8em;
      text-align: justify;
    }
  }
}
